<template>
  <el-dialog
    :title="$t('yh.yhfk')"
    :visible.sync="visible"
    center
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="closeriskdialog"
    @open="risk"
    :before-close="handleClose"
  >
    <el-form
      ref="riskform"
      :model="riskform"
      label-width="120px"
      class="riskform"
    >
      <el-form-item :label="$t('yh.yhfk')" prop="type">
        <el-radio-group v-model="riskform.type">
          <el-radio label="-1">{{ $t('yh.kuisun') }}</el-radio>
          <el-radio label="0">{{ $t('yh.zhengchang') }}</el-radio>
          <el-radio label="1">{{ $t('yh.yingli') }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="risksubmit">{{
        $t('tijiao')
      }}</el-button>
      <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      riskform: {
        type: 0,
        uid: ''
      }
    }
  },
  props: ['visible', 'uid'],
  methods: {
    handleClose() {
      this.$emit('close')
    },
    // 打开资产弹窗
    async risk() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      // 请求用户资产数据
      const { data } = await this.$http.get('/admin/user/risk/uid/' + this.uid)
      if (data) {
        if (data.code === 200) {
          this.riskform.type = data.data.risk
          this.riskform.uid = data.data.uid
        } else {
          this.$message.error(data.msg)
        }
      }
      loading.close()
    },
    // 关闭asset弹窗
    closeriskdialog() {
      this.$refs.riskform.resetFields()
      this.riskdialogvisible = false
    },
    async risksubmit() {
      const { data } = await this.$http.post('/admin/user/risk', this.riskform)
      if (data) {
        if (data.code === 200) {
          this.$message.success(this.$t('chenggong'))
          this.handleClose()
          this.$emit('update')
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
    }
  }
}
</script>
<style lang="less">
.riskform .el-form-item__content {
  text-align: center !important;
}
</style>
