<template>
  <el-dialog
    :title="$t('yh.bdqb')"
    :visible.sync="visible"
    center
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="closedialog"
    @open="haswallet"
    :before-close="handleClose"
  >
    <el-form
      ref="formref"
      :model="bindform"
      label-width="120px"
      :rules="formrules"
    >
      <el-form-item :label="$t('yh.bdqb')" prop="walletid">
        <el-select
          v-model="bindform.walletid"
          :placeholder="$t('qxz')"
          multiple
        >
          <el-option
            v-for="item in wallet"
            :key="item.id"
            :label="item.wallet_url"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="bindsubmit">{{
        $t('tijiao')
      }}</el-button>
      <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      wallet: [],
      bindform: {
        walletid: []
      },
      formrules: {
        walletid: [
          { required: true, message: '请选择钱包地址', trigger: 'blur' }
        ]
      }
    }
  },
  props: ['visible', 'uid'],
  methods: {
    handleClose() {
      this.$emit('close')
    },
    // 打开资产弹窗
    async haswallet() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      // 请求用户资产数据
      const { data } = await this.$http.get(
        '/admin/user/bindwallet/uid/' + this.uid
      )
      if (data) {
        if (data.code === 200) {
          this.wallet = data.data.wallet
          this.bindform.walletid = data.data.haswallet
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 关闭asset弹窗
    closedialog() {
      this.$refs.formref.resetFields()
      this.assetsdialogvisible = false
    },
    bindsubmit() {
      this.$refs.formref.validate(async valid => {
        if (!valid) return false
        this.bindform.uid = this.uid
        const { data } = await this.$http.post(
          '/admin/user/bindwallet',
          this.bindform
        )
        if (data) {
          if (data.code === 200) {
            this.$message.success(this.getlang(data.msg))
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
        this.handleClose()
      })
    }
  }
}
</script>
