<template>
  <el-dialog
    :title="$t('yh.gjrzxx')"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="closedialog"
    @open="open"
    :before-close="handleClose"
  >
    <el-divider content-position="left">{{ $t('yh.gjrzxx') }}</el-divider>
    <el-form ref="editref" :model="editform" label-width="120px">
      <el-form-item :label="$t('yh.zhiye')">
        <el-input v-model="editform.profession"></el-input>
      </el-form-item>
      <el-form-item :label="$t('yh.shouru')">
        <el-input v-model="editform.income"></el-input>
      </el-form-item>
      <el-form-item :label="$t('yh.lxdh')">
        <el-input v-model="editform.contact_number"></el-input>
      </el-form-item>
      <el-form-item :label="$t('yh.sfzzm')">
        <el-image
          style="width: 100px; height: 100px"
          :src="editform.identity_card_front_image"
          fit="fill"
        ></el-image>
      </el-form-item>
      <el-form-item :label="$t('yh.sfzfm')">
        <el-image
          style="width: 100px; height: 100px"
          :src="editform.identity_card_verso_image"
          fit="fill"
        ></el-image>
      </el-form-item>
      <el-form-item
        :label="$t('yh.sczjzp')"
        prop="user_with_identity_card_image"
      >
        <el-image
          style="width: 100px; height: 100px"
          :src="editform.user_with_identity_card_image"
          fit="fill"
        ></el-image>
      </el-form-item>
      <el-form-item :label="$t('yh.qtzm')" prop="other_image">
        <el-image
          style="width: 100px; height: 100px"
          :src="editform.other_image"
          fit="fill"
        >
          <div slot="error" class="image-slot">
            无
          </div>
        </el-image>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
      <el-button type="primary" @click="submit(4)">{{
        $t('tongguo')
      }}</el-button>
      <el-button type="danger" @click="submit(2)">{{ $t('bohui') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      editform: {}
    }
  },
  props: ['visible', 'uid'],
  methods: {
    // 关闭弹窗前操作
    handleClose() {
      this.$emit('close')
    },
    // 打开弹窗
    async open() {
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      // 请求数据
      const { data } = await this.$http.get(
        '/admin/user/edituser/id/' + this.uid
      )
      if (data) {
        if (data.code === 200) {
          this.editform = data.data
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
      loading.close()
    },
    // 关闭弹窗
    closedialog() {
      this.$refs.editref.resetFields()
    },
    // 提交操作
    async submit(status) {
      this.editform.oauth = status
      const { data } = await this.$http.post(
        '/admin/user/edituser',
        this.editform
      )
      if (data) {
        if (data.code === 200) {
          this.$message.success(this.$t('chenggong'))
          this.handleClose()
          this.$emit('update')
        } else {
          this.$message.error(this.getlang(data.msg))
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-alert {
  margin-top: 10px;
}
/*表单项宽度自适应  */
.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 350px;
}
.el-date-editor.el-input,
.el-select > .el-input,
.el-date-editor.el-input__inner {
  width: 350px;
}
.el-form-item__content .el-input-group {
  width: 50%;
}
.mobile .el-form-item__content .el-input-group {
  width: 100%;
}
.mobile .el-date-editor--daterange.el-input,
.mobile .el-date-editor--daterange.el-input__inner,
.mobile .el-date-editor--timerange.el-input,
.mobile .el-date-editor--timerange.el-input__inner {
  width: 100%;
}
.mobile .el-date-editor.el-input,
.mobile .el-select > .el-input,
.mobile .el-date-editor.el-input__inner {
  width: 100%;
}
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 80px;
}
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.el-row {
  line-height: 30px;
}
</style>
